import axios from "axios";
import { Message } from 'element-ui'

const instance = axios.create({
    baseURL: '/api',         // 默认地址
    timeout: 30000,                          // 超时时间
    headers: {
        // 'token' : ''                     // 默认添加配置请求头
    }
});

// 拦截器: 对 instance 这个对象 做一系列的加工处理, 最后返回
instance.interceptors.request.use(config => {
    // if (config.url === '/login') {              // 不能有token了还去请求login获取token
    //     localStorage.removeItem('token')
    // }
    let token = localStorage.getItem('token')
    // console.log(token)
    if (token) {
        config.headers.token = token            // 这里只管是否为空, 如果不为空，则给请求头加上这个属性
    }
    return config
} , err => {
    Promise.reject(err)
})

instance.interceptors.response.use(res => {
    if (res.data.code === 401) {
        // localStorage.removeItem('token')
        // location.href = '/login'
        Message({ 
            message: res.data.message, 
            type: 'error', 
            duration: 3 * 1000 
        })
    } else if (res.data.status === 200) {
        return res.data
    } else if (res.data.code === 500) {
        return res.data
    } else if (res.status === 400) {
        return res.data
    } else {
        error => {
            return Promise.reject(error);
        }
    }
}, res => {
    // return Promise.reject(error);
    return res.response.data
})

export default instance