import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import TestView from '../views/TestView.vue'
// import IndexView from '../views/IndexView.vue'
// import OperationsTableView from '../views/OperationsTableView.vue'
// import runningRouteAllView from '../views/runningRouteAllView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // component: IndexView,
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue'),
    meta: {
      // 设置页面标题
      title: '校易行后台可视化数据大屏',
    }
  },
  {
    path: '/test',
    name: 'testView',
    // component: TestView,
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue'),
    meta: {
      // 设置页面标题
      title: '测试页面',
    }
  },
  {
    path: '/operationsTable',
    name: 'operationsTable',
    // component: OperationsTableView,
    component: () => import(/* webpackChunkName: "about" */ '../views/OperationsTableView.vue'),
    meta: {
      // 设置页面标题
      title: '运营情况表',
    }
  },
  {
    path: '/runningRouteAll',
    name: 'runningRouteAll',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/runningRouteAllView.vue'),
    meta: {
      // 设置页面标题
      title: '行驶路线',
    }
  },
  {
    path: '/redCarSetup',
    name: 'redCarSetup',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/redCarSetupView.vue'),
    meta: {
      // 设置页面标题
      title: '红包车设置',
    }
  },
  {
    path: '/redCarPolicy',
    name: 'redCarPolicy',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/redCarPolicyView.vue'),
    meta: {
      // 设置页面标题
      title: '红包车策略',
    }
  },
  {
    path: '/wxComplaint',
    name: 'wxComplaint',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/wxComplaintView.vue'),
    meta: {
      // 设置页面标题
      title: '微信投诉',
    }
  },
  {
    path: '/revenueStatistics',
    name: 'revenueStatistics',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/revenueStatisticsView.vue'),
    meta: {
      // 设置页面标题
      title: '营收统计',
    }
  },
  {
    path: '/revenueStatisticscalculate',
    name: 'revenueStatisticscalculate',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/revenueStatisticsCalculateView.vue'),
    meta: {
      // 设置页面标题
      title: '营收统计',
    }
  },
  {
    path: '/BSDataEdition',
    name: 'BSDataEdition',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/BSDataEditionView.vue'),
    meta: {
      // 设置页面标题
      title: '大屏数据编辑',
    }
  },
  {
    path: '/allPath',
    name: 'AllPath',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/AllPathView.vue'),
    meta: {
      // 设置页面标题
      title: '时间段内路线查询',
    }
  },
  {
    path: '/perfSetting',
    name: 'perfSetting',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/PerfSettingView.vue'),
    meta: {
      // 设置页面标题
      title: '绩效设置',
    }
  },
  {
    path: '/appIrregularParkRecord',
    name: 'appIrregularParkRecord',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/appIrregularParkRecordView.vue'),
    meta: {
      // 设置页面标题
      title: '不规范停车',
    }
  },
  {
    path: '/appBikeConditionTrace',
    name: 'appBikeConditionTrace',
    // component: runningRouteAllView,
    component: () => import(/* webpackChunkName: "about" */ '../views/appBikeConditionTraceView.vue'),
    meta: {
      // 设置页面标题
      title: '车况列表',
    }
  },
  {
    path: '/about',
    name: 'about',
    // 路由懒加载, 跳转此页面时才会进行加载
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


/* 第二步：在路由守卫router.beforeEach中设置如下代码 */
router.beforeEach(async (to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {                // 判断是否有标题
    document.title = to.meta.title
  }else{                              // 无标题则默认显示
    document.title = '校易行后台可视化数据大屏';
  }
  await next();   // 最后执行下一步操作
})

export default router
